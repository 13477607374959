import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  Fragment,
  useLayoutEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useWindowSize } from '@reach/window-size'
import { browserHistory } from 'react-router'

import PlusIcon from '_assets/icons/plus.svg'
import RemoveIcon from '_assets/icons/remove-icon.svg'
import LogoInfo from '_assets/logo-presentation-info.svg'
import ButtonRound, { BUTTON_THEME } from '_components/button-round'
import PresentationLogoUploadModal from '_components/presentation-logo-upload-modal'
import PresentationStyleModal from '_components/presentation-style-modal'
import ConfirmationModal from '_components/confirmation-modal'
import ArchivedIcon from '_assets/icons/archive.svg'
import {
  updatePresentation,
  sharePresentationThroughEmail,
  COPY_PRESENTATION,
  SHARE_PRESENTATION_THROUGH_EMAIL,
  archivePresentation,
} from '_modules/presentations/actions'
import { updateUserLogo } from '_modules/user/actions'
import {
  updatePresentationLoadingSelector,
  updateLogoLoadingSelector,
  isSharingEmailLoadingSelector,
  currentPresentationIdSelector,
  isArchivingPresentationsSelector,
} from '_modules/presentations/selectors'
import { Presentation } from '_models'
import { getCurrentStyleIcon } from '_constants/presentation-styles'
import usePrevious from '_hooks/use-previous'
import EditPictureModal from '_components/edit-picture-modal'
import FAB from '_components/fab'
import EllipsisIcon from '_assets/icons/elipses.svg'
import Copy from '_assets/icons/copy-icon.svg'
import View from '_assets/icons/preview-icon.svg'
import LinkIcon from '_assets/icons/share-link.svg'
import EmailInviteIcon from '_assets/icons/envelope.svg'
import IndividualLinkModal from '_components/share-link-modal/individual-link'
import PublicLinkModal from '_components/share-link-modal/public-link'
import ShareEmailModalNew from '_components/share-email-modal'
import CopyModal from '_components/copy-modal'
import useFetchCall from '_hooks/use-fetch-call'
import useModal from '_hooks/use-modal'
import { capitalizeString } from '_utils/helpers'
import { isEditAssetLoadingSelector } from '_modules/assets/selectors'
import EarthIcon from '_assets/icons/earth.svg'
import NotifyUserButton from '_components/notify-user-button'

import styles from './styles.css'

const PADDING_SPACE = 8

const MENU_STYLE = {
  DARK: 'dark',
  LIGHT: 'light',
  LIGHT_GREY: 'light-grey',
}

const PresentationHeader = ({ presentation, isEditSharedPresentationModalOpen }) => {
  const [isStyleModalOpen, onToggleStyleModal] = useModal()
  const [isImageDropDownOpen, onToggleImageDropDown] = useModal()
  const [isConfirmationModalOpen, onToggleConfirmationModal] = useModal()
  const [isEditPhotoOpen, onToggleEditPhoto] = useModal()
  const [isShareLinkModalOpen, onToggleShareLinkModalOpen] = useModal()
  const [isShareLinkPublicModalOpen, onToggleShareLinkPublicModalOpen] = useModal()
  const [isShareEmailModalOpen, onToggleShareEmailModalOpen] = useModal()
  const [isCopyModalOpen, onToggleCopyModalOpen] = useModal()
  const [isArchiveConfirmationModal, onToggleArchiveConfirmationModal] = useModal()

  const [logoFile, setLogoFile] = useState()
  const [presentationImageDropDownStyle, setPresentationImageDropDownStyle] = useState()
  const [presentationStyleModalStyle, setPresentationStyleModalStyle] = useState()

  const { width, height } = useWindowSize()

  const isUpdatingPresentation = useSelector(updatePresentationLoadingSelector)
  const isEditAssetLoading = useSelector(isEditAssetLoadingSelector)
  const isUpdatingLogo = useSelector(updateLogoLoadingSelector)
  const isSharingEmail = useSelector(isSharingEmailLoadingSelector)
  const currentPresentationId = useSelector(currentPresentationIdSelector)
  const isLoadingArchivePresentation = useSelector(isArchivingPresentationsSelector)

  const wasUpdatingLogo = usePrevious(isUpdatingLogo)
  const wasUpdatingPresentation = usePrevious(isUpdatingPresentation)

  const dispatch = useDispatch()
  const styleButtonAddLogoRef = useRef(null)
  const styleButtonEditLogoRef = useRef(null)
  const styleButtonRef = useRef(null)

  const onTogglePreviewPresentation = useCallback(() => {
    browserHistory.push(`/presentations/preview/${presentation.get('id')}`)
  }, [presentation])

  const handleCopySuccessfully = useCallback(() => {
    browserHistory.push(`/presentations/edit/${currentPresentationId}`)
  }, [currentPresentationId])

  useFetchCall(COPY_PRESENTATION, handleCopySuccessfully)
  useFetchCall(SHARE_PRESENTATION_THROUGH_EMAIL, onToggleShareEmailModalOpen)

  const submenu = useMemo(
    () => [
      {
        sectionStyle: MENU_STYLE.LIGHT,
        list: [
          {
            name: 'PREVIEW',
            icon: View,
            onClick: onTogglePreviewPresentation,
          },
          {
            name: presentation.get('isWebpage') ? 'EDIT WEBPAGE' : 'CREATE A WEBPAGE',
            icon: EarthIcon,
            onClick: onToggleShareLinkPublicModalOpen,
          },
          {
            name: 'SHARE LINK',
            icon: LinkIcon,
            onClick: onToggleShareLinkModalOpen,
          },
          {
            name: 'SHARE WITH EMAIL',
            icon: EmailInviteIcon,
            onClick: onToggleShareEmailModalOpen,
          },
        ],
      },
      {
        sectionStyle: MENU_STYLE.DARK,
        list: [
          {
            name: presentation.get('template') ? 'USE' : 'DUPLICATE',
            icon: Copy,
            onClick: onToggleCopyModalOpen,
          },
          {
            name: 'ARCHIVE',
            icon: ArchivedIcon,
            onClick: onToggleArchiveConfirmationModal,
          },
        ],
      },
    ],
    [
      onTogglePreviewPresentation,
      onToggleShareLinkModalOpen,
      onToggleShareLinkPublicModalOpen,
      onToggleShareEmailModalOpen,
      presentation,
      onToggleCopyModalOpen,
      onToggleArchiveConfirmationModal,
    ]
  )

  const presentationAlreadyShared = useMemo(
    () => presentation && presentation.size && presentation.get('isSharedWithEmail'),
    [presentation]
  )

  const shareWithEmail = useCallback(
    payload => {
      dispatch(sharePresentationThroughEmail(presentation.get('id'), payload))
    },
    [dispatch, presentation]
  )

  const onArchivePresentation = useCallback(() => {
    dispatch(archivePresentation(presentation.get('id')))
  }, [dispatch, presentation])

  const onRemoveLogo = useCallback(() => {
    dispatch(updatePresentation(presentation.id, { logo: null }))
  }, [dispatch, presentation.id])

  const onChangePresentationStyle = useCallback(
    event => {
      const { value } = event.target

      dispatch(updatePresentation(presentation.id, { style: value }))
      onToggleStyleModal()
    },
    [dispatch, onToggleStyleModal, presentation.id]
  )

  const onFileSelect = useCallback(
    (_, dataURL) => {
      onToggleEditPhoto()
      setLogoFile(dataURL)
      onToggleImageDropDown()
    },
    [onToggleEditPhoto, onToggleImageDropDown]
  )

  const applyDefaultLogo = useCallback(
    newLogo => {
      dispatch(updatePresentation(presentation.id, { logo: newLogo }))
      onToggleImageDropDown()
    },
    [dispatch, onToggleImageDropDown, presentation.id]
  )

  const onConfirmChangePicture = useCallback(
    newLogo => {
      setLogoFile(newLogo.file)
      dispatch(updatePresentation(presentation.id, { logo: newLogo.file }))
      onToggleEditPhoto()
    },
    [dispatch, onToggleEditPhoto, presentation.id]
  )

  const onCloseConfirmationModal = useCallback(() => {
    onToggleConfirmationModal()
    setLogoFile(undefined)
  }, [onToggleConfirmationModal])

  const onFinishConfirmationModal = useCallback(() => {
    dispatch(updateUserLogo({ picture: logoFile }))
    setLogoFile(undefined)
  }, [dispatch, logoFile])

  const getModalPosition = useCallback((element, setWith) => {
    const boundingClientRect = element.getBoundingClientRect()

    const elementWidth = boundingClientRect.width
    const yPosition = boundingClientRect.bottom + window.scrollY + PADDING_SPACE
    const xPosition = boundingClientRect.left + (elementWidth - setWith) / 2

    return {
      top: `${yPosition}px`,
      left: `${xPosition < 0 ? 0 : xPosition}px`,
    }
  }, [])

  useEffect(() => {
    if (
      (logoFile && !isUpdatingPresentation && wasUpdatingPresentation) ||
      (!isUpdatingLogo && wasUpdatingLogo)
    ) {
      onToggleConfirmationModal()
    }

    return () => {}
  }, [
    isUpdatingLogo,
    isUpdatingPresentation,
    logoFile,
    onToggleConfirmationModal,
    presentation.logo,
    wasUpdatingLogo,
    wasUpdatingPresentation,
  ])

  useLayoutEffect(() => {
    const imagePositionDropdownWidth = 180
    if (
      !presentation.logo &&
      isImageDropDownOpen &&
      styleButtonAddLogoRef &&
      styleButtonAddLogoRef.current
    ) {
      setPresentationImageDropDownStyle({
        content: getModalPosition(styleButtonAddLogoRef.current, imagePositionDropdownWidth),
      })
    }
  }, [width, height, getModalPosition, presentation.logo, isImageDropDownOpen])

  useLayoutEffect(() => {
    const imagePositionDropdownWidth = 180
    if (isStyleModalOpen && styleButtonRef && styleButtonRef.current) {
      setPresentationStyleModalStyle({
        content: getModalPosition(styleButtonRef.current, imagePositionDropdownWidth),
      })
    }
  }, [width, height, getModalPosition, isStyleModalOpen])

  useLayoutEffect(() => {
    const imagePositionDropdownWidth = 180
    if (
      presentation.logo &&
      isImageDropDownOpen &&
      styleButtonEditLogoRef &&
      styleButtonEditLogoRef.current
    ) {
      setPresentationImageDropDownStyle({
        content: getModalPosition(styleButtonEditLogoRef.current, imagePositionDropdownWidth),
      })
    }
  }, [width, height, presentation.logo, getModalPosition, isImageDropDownOpen])

  return (
    <div className={styles.header}>
      <div className={styles['header-content']}>
        {presentation.logo ? (
          <div className={styles['logo-container']}>
            <Fragment>
              <button
                className={styles['logo-button']}
                onClick={onToggleImageDropDown}
                ref={styleButtonEditLogoRef}
                aria-label="Add logo"
                type="button"
              >
                <img src={presentation.logo} alt="logo" className={styles.logo} />
              </button>
              <button onClick={onRemoveLogo} aria-label="Remove logo" type="button">
                <svg className={styles['logo-remove-button']} aria-hidden="true">
                  <title>Remove logo</title>
                  <use xlinkHref={RemoveIcon} />
                </svg>
              </button>
              <p className={styles['save-status']}>
                {isUpdatingPresentation || isEditAssetLoading ? 'SAVING' : 'SAVED'}
              </p>
            </Fragment>
          </div>
        ) : (
          <div className={styles.wrapper}>
            <ButtonRound
              theme={BUTTON_THEME.SECONDARY}
              startIcon={PlusIcon}
              onClick={onToggleImageDropDown}
              ref={styleButtonAddLogoRef}
            >
              Add Logo
            </ButtonRound>
            <p className={styles['save-status']}>{isUpdatingPresentation ? 'SAVING' : 'SAVED'}</p>
          </div>
        )}
        <ButtonRound
          theme={BUTTON_THEME.SECONDARY}
          startIcon={getCurrentStyleIcon[presentation.style]}
          onClick={onToggleStyleModal}
          ref={styleButtonRef}
        >
          {capitalizeString(presentation.style)}
        </ButtonRound>
      </div>
      <FAB
        id="insights-options"
        alt="insights options"
        icon={EllipsisIcon}
        cancelIcon={PlusIcon}
        className={styles.fab}
        submenuClassName={styles.submenu}
        submenu={submenu}
      />
      {presentationAlreadyShared && (
        <NotifyUserButton
          isOpen={presentationAlreadyShared}
          isEditSharedPresentationModalOpen={isEditSharedPresentationModalOpen}
          presentationId={presentation.get('id')}
        />
      )}
      {isImageDropDownOpen && (
        <PresentationLogoUploadModal
          logo={presentation.logo}
          isOpen
          onRequestClose={onToggleImageDropDown}
          selectFile={onFileSelect}
          style={presentationImageDropDownStyle}
          applyDefaultLogo={applyDefaultLogo}
        />
      )}
      {isStyleModalOpen && (
        <PresentationStyleModal
          isOpen
          onClickOption={onChangePresentationStyle}
          onRequestClose={onToggleStyleModal}
          shouldCloseOnEsc
          style={presentationStyleModalStyle}
          value={presentation.style}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title="Apply logo on all presentations?"
          description="Would you like to apply this logo to all of your presentations from now on? This action will not modify any presentations previously created."
          image={LogoInfo}
          isOpen
          isLoading={isUpdatingLogo}
          onCloseText="No, apply only to this presentation"
          onFinishText="Yes, apply to all"
          onClose={onCloseConfirmationModal}
          onFinish={onFinishConfirmationModal}
        />
      )}
      {isEditPhotoOpen && (
        <EditPictureModal
          label="picture"
          onClose={onToggleEditPhoto}
          picture={logoFile}
          onFinish={onConfirmChangePicture}
        />
      )}
      {isShareLinkModalOpen && (
        <IndividualLinkModal
          onClose={onToggleShareLinkModalOpen}
          presentationId={presentation.get('id')}
        />
      )}
      {isShareLinkPublicModalOpen && (
        <PublicLinkModal
          presentationId={presentation.get('id')}
          onClose={onToggleShareLinkPublicModalOpen}
        />
      )}
      {isShareEmailModalOpen && (
        <ShareEmailModalNew
          onClose={onToggleShareEmailModalOpen}
          presentation={presentation}
          sendEmail={shareWithEmail}
          isSharing={isSharingEmail}
        />
      )}

      {isCopyModalOpen && (
        <CopyModal
          isOpen={isCopyModalOpen}
          dismiss={onToggleCopyModalOpen}
          presentationName={presentation.get('cleanTitle')}
          presentationId={presentation.get('id')}
        />
      )}
      {isArchiveConfirmationModal && (
        <ConfirmationModal
          isOpen
          isClosable
          isLoading={isLoadingArchivePresentation}
          title="Archive presentation"
          description="Are you sure you want to archive this presentation? Anyone you've shared this presentation with will no longer have access to it. You can always restore it later."
          onCloseText="No, keep presentation"
          onFinishText="Yes, archive presentation"
          onClose={onToggleArchiveConfirmationModal}
          onFinish={onArchivePresentation}
          modalClassNames={styles['archive-presentation-modal']}
        />
      )}
    </div>
  )
}

PresentationHeader.propTypes = {
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  isEditSharedPresentationModalOpen: PropTypes.bool.isRequired,
}

export default React.memo(PresentationHeader)
